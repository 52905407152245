<template>
  <div>
    <b-card style="background-color: #e9e9e9">
      <b-container fluid>
        <div class="login-form login-signin">
          <!--  <div class="text-center mb-10 mb-lg-20">
        <strong class="font-size-h1">اسمنت اليمامة</strong><br>
        <strong class="font-size-h1">YAMAMA CEMENT</strong>
      </div>-->

          <!--begin::Form-->
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div
              role="alert"
              v-bind:class="{ show: errors.length > 0 }"
              class="alert fade alert-danger"
            >
              <div class="alert-text">
                {{ errors }}
              </div>
            </div>

            <b-form-group
              id="example-input-group-1"
              label="الإيميل"
              label-for="example-input-1"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-3 px-6"
                id="example-input-1"
                placeholder="الإيميل"
                name="example-input-1"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                type="email"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-1-live-feedback">
                الإيميل مطلوب
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="example-input-group-2"
              label="كلمة المرور"
              label-for="example-input-2"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-3 px-6"
                placeholder="كلمة المرور"
                type="password"
                id="example-input-2"
                name="example-input-2"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="input-2-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-2-live-feedback">
                كلمة السر مطلوبة
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.rememberMe"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              تذكر كلمة السر
            </b-form-checkbox>
            <br />
            <!--begin::Action-->
            <div
              class="
                form-group
                d-flex
                flex-wrap
                justify-content-between
                align-items-center
              "
            >
              <button
                ref="kt_login_signin_submit"
                style="background-color: gray; color: white"
                class="btn font-weight-bold h-auto py-2 px-6"
              >
                تسجيل الدخول
              </button>
              <a
                @click.prevent="forgetPassword"
                style="color: gray"
                class="font-weight-bold font-size-3 ml-5 mr-5"
              >
                <u>هل نسيت كلمة السر ؟</u>
              </a>
            </div>
            <div
              class="
                text-right
                mt-5
                mb-15 mb-lg-0
                flex-column-auto
                justify-content-center
                py-5
                px-10
              "
            >
              <span class="font-weight-bold font-size-3 text-dark-60"> </span>
            </div>
            <!--end::Action-->
          </b-form>
          <!--end::Form-->
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import {
  LOGIN,
  LOGOUT,
  FORGET_PASSWORD
} from "@/core/services/store/auth.module";
// import {LOGIN} from "@/core/services/store/login.module"

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      forget: false,
      form: {
        email: "",
        password: ""
        // rememberMe:false
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    forgetPassword() {
      this.$v.form.email.$touch();
      if (this.$v.form.email.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      this.$store.dispatch(LOGOUT);
      setTimeout(() => {
        this.$store
          .dispatch(FORGET_PASSWORD, {
            e_mail: email
          })
          .then(() => {
      
            this.$router.push("/verify-forget-code");
          });
      }, 2000);
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const rememberMe = this.form.rememberMe;
      // const myemail=localStorage.setItem("myemai",email)

      //
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        this.$store
          .dispatch(LOGIN, {
            e_mail: email,
            password: password,
            rememberMe: rememberMe
          })
          .then(() => {
        
            // this.$store.dispatch(GENERATE_CODE)
            this.$router.push("/verify-login-code");
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
